












import { Vue, Component, Prop } from 'vue-property-decorator';
import { LempiBank } from '@/common/models/Banks';
import moneyFormat from '@/common/functions/moneyFormat';

interface Detail {
  bank: LempiBank;
  account: string;
  fee: number;
  amount: number;
  debitAmount: number;
  finalBalance: number;
}

@Component({
  name: 'WithdrawDetail',
  filters: {
    moneyFormat,
  },
})
export default class WithdrawDetail extends Vue {
  @Prop({ required: true }) detail!: Detail;
}
