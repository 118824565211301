









































































import { Vue, Component, Ref } from 'vue-property-decorator';
import { BANK_LIST } from '@/common/models/Banks';
import { getWalletDetail, requestWalletWithdraw } from '@/common/service/WalletApi';

import moneyFormat, { isValidCurrencyFormat } from '@/common/functions/moneyFormat';
import NotificationService from '@/components/Notification.service';
import WithdrawDetail from '@/components/WithdrawDetail.vue';
import { WalletDetail } from '@/common/models/Wallet';

@Component({
  name: 'WithdrawView',
  components: {
    WithdrawDetail,
  },
})
export default class WithdrawView extends Vue {
  @Ref('bankForm') readonly bankForm!: HTMLFormElement;

  account = '';

  amount = '0';

  selectedBank = -1;

  wallet: WalletDetail = {
    id: -1,
    uuid: '',
    balance: 0,
    transactions: [],
  };

  confirmWithdraw = false;

  created() {
    this.getWallet();
  }

  async getWallet() {
    const walletDetail = await getWalletDetail(this.$route.params.id);
    this.wallet = walletDetail;
  }

  featMvp = true;

  banks = BANK_LIST.filter(
    (b) => b.withdrawActive || (b.withdrawActive === undefined && b.isActive)
  );

  get rules() {
    return {
      account: [(v: string) => !!v || 'Cuenta es requerida'],
      bank: [(v: number) => v !== -1 || 'Banco es requerido'],
      currency: [
        (v: string) => !!v || 'Cantidad es requerida',
        (v: string) => isValidCurrencyFormat(v) || 'Tiene que tener el formato de moneda',
        () => this.finalBalance >= 0 || 'Fondos insuficientes',
        (v: string) => Number(v) >= 50 || 'Cantidad tiene que ser L. 50 o mayor',
      ],
      finalBalance: [(v: string) => Number(v) >= 0 || 'Balance final tiene que ser mayor a 0'],
    };
  }

  createBank() {
    this.$router.back();
  }

  get withdrawDetail() {
    return {
      bank: this.banks.find((b) => b.id === this.selectedBank),
      account: this.account,
      fee: this.fee,
      amount: this.amount,
      debitAmount: this.debitAmount,
      finalBalance: this.finalBalance,
    };
  }

  async withdraw() {
    const valid = this.bankForm.validate();
    if (!valid) {
      return;
    }

    if (!this.confirmWithdraw) {
      this.confirmWithdraw = true;
      return;
    }

    const res = await requestWalletWithdraw(this.$route.params.id, {
      account: this.account,
      bank: this.selectedBank,
      amount: Number(this.amount),
    });
    if (res.error) {
      NotificationService.show({
        text: res.error,
        color: 'error',
      });
      return;
    }
    this.$router.back();
  }

  get walletBalance() {
    return moneyFormat(this.wallet.balance);
  }

  get fee() {
    const amount = Number(this.amount);
    const fee = Math.round(amount * 2) / 100;
    return fee > 25 ? 25 : fee;
  }

  get debitAmount() {
    const amount = Number(this.amount);
    return this.fee + amount;
  }

  get finalBalance() {
    const finalBalance = this.wallet.balance - this.debitAmount;
    return Math.round(finalBalance * 100) / 100;
  }
}
