






















































































import { Vue, Component } from 'vue-property-decorator';

import {
  RawTransaction as Transaction,
  TRANSACTION_TYPE,
  EMPTY_TRANSACTION,
} from '@/common/models/Transaction';
import { WalletDetail } from '@/common/models/Wallet';
import { getWalletDetail } from '@/common/service/WalletApi';
import moneyFormat from '@/common/functions/moneyFormat';
import dateTimeFormat from '@/common/functions/dateTimeFormat';

import TransactionDetail from '@/components/TransactionDetail.vue';
import QrCodeDialog from '@/components/QrCodeDialog.vue';

@Component({
  name: 'WalletDetailView',
  filters: {
    moneyFormat,
    dateTimeFormat,
  },
  components: {
    TransactionDetail,
    QrCodeDialog,
  },
})
export default class WalletDetailView extends Vue {
  wallet: WalletDetail = {
    id: -1,
    uuid: '',
    balance: 0,
    transactions: [],
  };

  dialog = false;

  qrSrc?: string | Buffer | ArrayBuffer = '';

  qrDialog = false;

  selectedTransaction: Transaction = EMPTY_TRANSACTION;

  async created() {
    this.wallet = await getWalletDetail(this.$route.params.id);
  }

  getIcon = (type: number) => {
    if (type === TRANSACTION_TYPE.IN) {
      return 'mdi-download';
    }
    if (type === TRANSACTION_TYPE.OUT) {
      return 'mdi-upload';
    }
    return '';
  };

  getIconColor = (type: number) => {
    if (type === TRANSACTION_TYPE.IN) {
      return 'success';
    }
    if (type === TRANSACTION_TYPE.OUT) {
      return 'error';
    }
    return '';
  };

  onClickTransaction(transaction: Transaction) {
    this.selectedTransaction = transaction;
    this.dialog = true;
  }
}
